export enum InitiativeApiEnum {
  AttachContentToInitiative = 'initiative/attach-content-to-initiative',
  CheckFileExist = 'initiative/is-file-exist',
  CreateUpdateInitiative = 'initiative/create-or-update',
  Delete = 'initiative/delete',
  GetAllInitiatives = 'initiative/get-all-initiatives',
  GetInitiativeAndProgressDetailsById = 'initiative/get-initiative-and-progress-details',
  GetInitiativeAttachedDetails = 'initiative/get-initiatives-with-attached-content-details',
  GetInitiativeFiles = 'initiative/get-saved-files',
  GetInitiativeScaleTypes = 'initiative/get-region-scale-types',
  GetInitiativesAndProgressDetailsByUserId = 'initiative/get-user-all-initiatives',
  GetNewRecommendationsCount = 'initiative/get-new-recommendations-count',
  GetRecommendedArticles = 'initiative/get-recommended-articles',
  GetRecommendedCommunityUsers = 'initiative/get-recommended-community-users',
  GetRecommendedConversations = 'initiative/get-recommended-conversations',
  GetRecommendedProjects = 'initiative/get-recommended-projects',
  GetRecommendedTools = 'initiative/get-recommended-tools',
  GetSavedInitiativeArticle = 'initiative/get-saved-articles',
  GetSavedInitiativeCommunityUsers = 'initiative/get-saved-community-users',
  GetSavedInitiativeConversations = 'initiative/get-saved-conversations',
  GetSavedInitiativeProjects = 'initiative/get-saved-projects',
  GetSavedInitiativeTools = 'initiative/get-saved-tools',
  RemoveSavedContent = 'initiative/remove-saved-module-content-item',
  SaveInitiativeContentItem = 'initiative/save-selected-content-items',
  SaveInitiativeFile = 'initiative/upload-file',
  UpdateInitiativeContentLastViewedDate = 'initiative/update-initiative-content-lastViewedDate',
  UpdateInitiativeSubStep = 'initiative/update-initiative-substep',
  ExportInitiatives = 'initiative/export-all-initiatives',
  UpdateFileModifiedDate = 'initiative/update-initiative-file-modifiedDate'
}
